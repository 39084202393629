<div class="modal-dialog modal-lg custom-modal" role="document">
  <div class="modal-content">
    <div class="modal-header text-center" style="text-align: center">
      <h5
        class="modal-title w-100"
        *ngIf="formModel.Id > 0"
        id="exampleModalCenterTitle"
      >
        {{
          isProject()
            ? ("Edit Project" | translate)
            : ("Edit Department" | translate)
        }}
      </h5>
      <h5
        class="modal-title w-100"
        *ngIf="formModel.Id === 0"
        id="exampleModalCenterTitle"
      >
        {{
          isProject()
            ? ("Add New Project" | translate)
            : ("Add New Department" | translate)
        }}
      </h5>
    </div>
    <div class="modal-body">
      <form
        class="user"
        id="addDepartment"
        #addDepartmentForm="ngForm"
        autocomplete="off"
        (submit)="onSubmit(addDepartmentForm)"
      >
        <input type="hidden" name="Id" [(ngModel)]="formModel.Id" />

        <!-- Name und Typ -->
        <div class="row">
          <!-- Name -->
          <div class="form-group col-12 col-md-6 mb-4">
            <label for="Name" class="required-label">{{
              "Name" | translate
            }}</label>
            <input
              type="text"
              class="form-control"
              id="Name"
              name="Name"
              [(ngModel)]="formModel.Name"
              required
              placeholder="{{ 'Enter Name' | translate }}"
            />
          </div>

          <!-- Typ auswählen, wenn WorkStructure auf Both steht -->
          <div
            class="form-group col-12 col-md-6 mb-4"
            *ngIf="workStructure === workStructureEnum.Both"
          >
            <label>{{ "Type" | translate }}</label>
            <div
              class="btn-group flex-wrap col-md-12"
              role="group"
              aria-label="Type Toggle"
            >
              <input
                type="radio"
                class="btn-check"
                [value]="departmentTypeEnum.Department"
                [(ngModel)]="formModel.Type"
                name="Type"
                id="btnradioDepartment"
                autocomplete="off"
                required
              />
              <label class="btn btn-outline-primary" for="btnradioDepartment">
                <img
                  src="./assets/icons/department.png"
                  style="width: 25px; height: 25px; margin-right: 5px"
                  alt="{{ 'DEPARTMENT_ICON_ALT' | translate }}"
                />
                {{ "Department" | translate }}
              </label>

              <input
                type="radio"
                class="btn-check"
                [value]="departmentTypeEnum.Project"
                [(ngModel)]="formModel.Type"
                name="Type"
                id="btnradioProject"
                autocomplete="off"
                required
              />
              <label class="btn btn-outline-primary" for="btnradioProject">
                <img
                  src="./assets/icons/project-management.png"
                  style="width: 25px; height: 25px; margin-right: 5px"
                  alt="{{ 'PROJECT_ICON_ALT' | translate }}"
                />
                {{ "Project" | translate }}
              </label>
            </div>
          </div>
        </div>

        <!-- Parent Department und Projektleiter -->
        <div class="row">
          <!-- Parent Department -->
          <div class="form-group col-12 col-md-6 mb-4">
            <label for="ParentDepartmentId">{{
              "Parent Department" | translate
            }}</label>
            <mat-select
              id="ParentDepartmentId"
              class="form-control"
              placeholder="{{ 'Select Parent Department' | translate }}"
              name="ParentDepartmentId"
              [(ngModel)]="formModel.ParentDepartmentId"
            >
              <mat-option [value]="null"
                >{{ "No Parent" | translate }}
              </mat-option>
              <mat-option
                *ngFor="let dept of departmentList"
                [value]="dept.Id"
                [disabled]="
                  dept.Id === formModel.Id ||
                  (formModel.Type === departmentTypeEnum.Department &&
                    dept.Type === departmentTypeEnum.Project)
                "
              >
                {{ dept.Name }}
              </mat-option>
            </mat-select>
          </div>

          <!-- Skills (Multiple Auswahl) -->
          <div class="form-group col-12 col-md-6 mb-4">
            <label for="SkillIds">{{ "ABILITY/COMPETENCE" | translate }}</label>
            <mat-select
              id="SkillIds"
              class="form-control"
              placeholder="{{ 'Select Skills' | translate }}"
              [formControl]="skillValue"
              [(ngModel)]="formModel.SkillIds"
              multiple
            >
              <mat-option *ngFor="let skill of skillList" [value]="skill.Id">
                {{ skill.Name }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <div *ngIf="isProject()">
          <div class="row">
            <!-- Projektleiter (nur für Projekte) -->
            <div class="form-group col-12 col-md-6 mb-4" *ngIf="isProject()">
              <label for="ProjectManagerId">{{
                "Project Manager" | translate
              }}</label>
              <mat-select
                [formControl]="projectManagerCtrl"
                id="ProjectManagerId"
                class="form-control"
                placeholder="{{ 'Select Project Manager' | translate }}"
              >
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="projectManagerFilterCtrl"
                    placeholderLabel="{{ 'Search' | translate }}"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="null"
                  >{{ "No Manager" | translate }}
                </mat-option>
                <mat-option
                  *ngFor="let emp of filteredProjectManagers | async"
                  [value]="emp"
                >
                  {{ emp.FirstName }} {{ emp.LastName }}
                </mat-option>
              </mat-select>
            </div>
            <!-- Beschreibung -->
            <div class="form-group col-12 mb-4">
              <label for="Description">{{ "Description" | translate }}</label>
              <textarea
                class="form-control"
                id="Description"
                name="Description"
                [(ngModel)]="formModel.Description"
              ></textarea>
            </div>
          </div>

          <div class="row">
            <!-- Projektstart und -ende -->
            <div class="form-group col-12 col-md-6 mb-4">
              <mat-label class="required-label">{{
                "Project Start Date" | translate
              }}</mat-label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span
                    (click)="startPicker.open()"
                    class="input-group-text"
                    id="start-time-icon"
                    style="height: 40px"
                  >
                    <i class="fa-light fa-calendar-days"></i>
                  </span>
                </div>
                <input
                  matInput
                  [matDatepicker]="startPicker"
                  class="form-control"
                  id="ProjectStart"
                  name="ProjectStart"
                  (click)="startPicker.open()"
                  [(ngModel)]="formModel.ProjectStart"
                  required
                  placeholder="{{ 'Select Start Date' | translate }}"
                />
                <mat-datepicker #startPicker></mat-datepicker>
              </div>
            </div>

            <div class="form-group col-12 col-md-6 mb-4">
              <mat-label>{{ "Project End Date" | translate }}</mat-label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span
                    (click)="endPicker.open()"
                    class="input-group-text"
                    id="end-time-icon"
                    style="height: 40px"
                  >
                    <i class="fa-light fa-calendar-days"></i>
                  </span>
                </div>
                <input
                  matInput
                  [matDatepicker]="endPicker"
                  class="form-control"
                  id="ProjectEnd"
                  name="ProjectEnd"
                  (click)="endPicker.open()"
                  [(ngModel)]="formModel.ProjectEnd"
                  placeholder="{{ 'Select End Date' | translate }}"
                />
                <mat-datepicker #endPicker></mat-datepicker>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Projektstatus und Abschluss -->
            <div class="form-group col-12 col-md-6 mb-4">
              <label for="Status">{{ "Project Status" | translate }}</label>
              <mat-select
                id="Status"
                class="form-control"
                placeholder="{{ 'Select Project Status' | translate }}"
                name="Status"
                [(ngModel)]="formModel.Status"
                required
              >
                <mat-option
                  *ngFor="let status of projectStatusList"
                  [value]="status.value"
                >
                  {{ status.label | translate }}
                </mat-option>
              </mat-select>
            </div>

            <div class="form-group col-12 col-md-6 mb-4">
              <mat-checkbox
                [(ngModel)]="formModel.IsProjectCompleted"
                name="IsProjectCompleted"
              >
                {{ "Project Completed" | translate }}
              </mat-checkbox>
            </div>
          </div>
        </div>
      </form>

      <!-- Bedingt interne Buttons anzeigen -->
      <div
        *ngIf="showButtons"
        class="modal-footer d-flex justify-content-between"
      >
        <button
          class="btn btn-outline-secondary"
          (click)="onClose()"
          type="button"
          data-dismiss="modal"
        >
          {{ "Close" | translate }}
        </button>
        <button
          class="btn btn-primary active"
          form="addDepartment"
          type="submit"
          [disabled]="!addDepartmentForm.valid"
        >
          {{ "Save changes" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
