import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg, EventApi } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import multiMonthPlugin from '@fullcalendar/multimonth';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import tippy, {
  followCursor,
  hideAll,
  inlinePositioning,
  roundArrow,
} from 'tippy.js';
import { AbsenceService } from '../../services/absence.service';
import { AcceptAbsenceComponent } from '../accept-absence/accept-absence.component';
import { AddAbsenceComponent } from '../add-absence/add-absence.component';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import { AbsenceType, EventType } from 'src/app/core/services/const';

dayjs.extend(utc);
@Component({
  selector: 'app-absence-calendar',
  templateUrl: './absence-calendar.component.html',
  styleUrls: ['./absence-calendar.component.css'],
})
export class AbsenceCalendarComponent implements OnInit, AfterViewInit {
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin, multiMonthPlugin],
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    locale: this.translate.currentLang,
    firstDay: this.companySetting.CompanySettings.CalendarOptions.FirstDay,
    height: 'auto',
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'absenceDayGridMonth,absenceMultiMonthYear,addAbsenceButton',
    },
    customButtons: {
      addAbsenceButton: {
        text: this.translate.instant('ADD'),
        click: () => this.addAbsence(),
      },
      prev: {
        click: () => this.selectPrevButton(),
      },
      next: {
        click: () => this.selectNextButton(),
      },
      absenceMultiMonthYear: {
        text: this.translate.instant('YEAR'),
        click: () => this.selectYearButton(),
      },
      absenceDayGridMonth: {
        text: this.translate.instant('Month'),
        click: () => this.absenceDayGridMonthButton(),
      },
    },
    displayEventTime: true,
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    // multiMonthMinWidth: 600,
    select: this.handleDateSelect.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventChange: this.handleEventChange.bind(this),
    eventAdd: this.handleEventAdd.bind(this),
    eventReceive: this.handleDropInfo.bind(this),
    handleWindowResize: true,
    eventContent: this.handleEventContent.bind(this),
    eventDidMount: this.handleEventDidMount.bind(this),
    eventClassNames: this.eventClassNames.bind(this),
    dayCellClassNames: this.handleDayCellClassNames.bind(this),
    dayHeaderFormat: { weekday: 'long' },
    eventConstraint: {
      start: dayjs().format('YYYY-MM-DD'),
      end: '2100-01-01',
    },
    selectConstraint: {
      start: dayjs().format('YYYY-MM-DD'),
      end: '2100-01-01',
    },
    views: {
      dayGridYear: {
        type: 'multiMonthYear',
        duration: { years: 1 },
        fixedWeekCount: false,
      },
    },
  };

  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  calendarVisible = true;
  currentEvents: EventApi[] = [];
  calendarApi: any;
  constructor(
    private service: AbsenceService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private translate: TranslateService,
    private el: ElementRef,
    private companySetting: CompanySettingsService
  ) {
    Window['AbsenceCalendarComponent'] = this;
  }
  private subscriptionEmployeeEventList: Subscription;
  HTMLElement: HTMLImageElement;

  ngOnInit(): void {
    this.subscriptionEmployeeEventList =
      this.service.employeeAbsenceList.subscribe((list) => {
        if (this.calendarComponent) {
          const calendar = this.calendarComponent.getApi();
          if (
            calendar &&
            JSON.stringify(this.calendarOptions.events) !== JSON.stringify(list)
          ) {
            calendar.removeAllEvents();
          }
        }

        this.calendarOptions.events = list;

        if (this.HTMLElement?.textContent) {
          this.HTMLElement.textContent =
            ' ' + this.translate.instant('Absence Calendar') + ' ';
        }
      });
  }

  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();
    const calendar = this.calendarComponent.getApi();
    this.service.getAbsenceAdmin(
      calendar.getCurrentData().viewApi.currentStart,
      calendar.getCurrentData().viewApi.currentEnd
    );
    this.HTMLElement = this.el.nativeElement.querySelector('#fc-dom-1');
  }
  handleCalendarToggle(): void {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle(): void {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  addAbsence(startTime = null, endTime = null): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false; // Ändern Sie dies auf false
    dialogConfig.restoreFocus = false; // Fügen Sie diese Zeile hinzu
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '90vw';
    dialogConfig.data = { startTime, endTime };
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(AddAbsenceComponent, dialogConfig);
  }

  acceptRequest(Id, EmployeeId, Title): void {
    setTimeout(() => {
      hideAll();
    }, 100);
    // get the event from the calendar and pass it to the dialog
    const calendar = this.calendarComponent.getApi();
    const event = calendar.getEventById(Id);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '95vw';
    dialogConfig.data = {
      Id: Id,
      EmployeeId: EmployeeId,
      Title: event.title,
      AbsenceType: event._def.extendedProps.absenceType.AbsenceType,
      StarTime: event.start,
      EndTime: event.end,
      End: event.end,
      Start: event.start,
      EmployeeName: event.title,
      AllDay: event.allDay,
    };
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(AcceptAbsenceComponent, dialogConfig);
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    this.addAbsence(selectInfo.start, selectInfo.end);
  }

  deleteAbsence(absenceId): void {
    // after click on tippy close the tippy and open the dialog
    setTimeout(() => {
      hideAll();
    }, 100);
    const calendar = this.calendarComponent.getApi();
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });

    const absenceType =
      calendar.getEventById(absenceId).extendedProps.absenceType;
    if (absenceType === 2) {
      this.dialogRef.componentInstance.confirmMessage = this.translate.instant(
        'Do you want to delete this sick day'
      );
    } else if (absenceType === 1) {
      this.dialogRef.componentInstance.confirmMessage = this.translate.instant(
        'Do you want to delete this vacation day'
      );
    }

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.service.deleteAbsence(
          absenceId,
          calendar.getCurrentData().viewApi.currentStart
        );
      }
      this.dialogRef = null;
    });
  }
  handleEventDidMount(info): any {
    let acceptRequest = !(
      info.event.extendedProps.requestStatus === 1 ||
      info.event.extendedProps.requestStatus === 2
    )
      ? ''
      : '<span onclick="Window.AbsenceCalendarComponent.acceptRequest(\'' +
        info.event.id +
        "','" +
        info.event.extendedProps.employeeId +
        "','" +
        info.event.title +
        '\')" ><i class="bi bi-calendar-check fa-lg fa-clickable">' +
        '</i></span>';
    tippy(info.el, {
      content:
        '<div>' +
        acceptRequest +
        '<span onclick="Window.AbsenceCalendarComponent.deleteAbsence(\'' +
        info.event.id +
        '\')" ><i class="far fa-trash-alt fa-lg fa-clickable">' +
        '</i></span>' +
        '</div>',
      allowHTML: true,
      plugins: [inlinePositioning, followCursor],
      theme: 'light',
      trigger: 'click',
      followCursor: 'initial',
      arrow: roundArrow,
      popperOptions: { strategy: 'fixed' },
      interactive: true,
      interactiveBorder: 30,
      interactiveDebounce: 0,
      appendTo: document.body,
      animation: 'scale-extreme',
      onMount(instance): void {
        const box = instance.popper.firstElementChild;
        requestAnimationFrame(() => {
          box.classList.add('animated');
          box.classList.add('wobble');
        });
      },
    });
  }

  handleEvents(events: EventApi[]): void {
    this.currentEvents = events;
    this.cdr.detectChanges();
  }

  handleEventChange(events: any): void {}

  handleEventAdd(events: EventApi[]): void {
    var test = events;
    this.cdr.detectChanges();
  }

  handleDropInfo(events: any): void {}
  handleEventContent(event): any {
    let content = '';

    const fontColor =
      event.event.extendedProps.eventType === 5 ? 'color: #000000' : '';
    // Name des Mitarbeiters
    content +=
      '<strong style="white-space: nowrap; ' +
      fontColor +
      '">' +
      event.event.title +
      '</strong><br>';

    // Art des Eintrags
    let absenceType = this.translate.instant('Vacation');
    if (event.event.extendedProps.absenceType === AbsenceType.Sick) {
      absenceType = this.translate.instant('Sick');
    }

    let requestStatusText = '';
    if (event.event.extendedProps.requestStatus === 4) {
      requestStatusText = this.translate.instant('REJECTED');
    }

    if (event.event.extendedProps.eventType === EventType.Holiday) {
      absenceType = this.translate.instant('HOLIDAY');
    }

    content +=
      '<em>' + absenceType + '</em> <b>' + requestStatusText + ' </b> ';

    // Zeitraum
    if (event.event.allDay) {
      // Ganztägiger Eintrag
      // Ganztägiger Eintrag
      const startDate = dayjs.utc(event.event.start).format('DD.MM');
      const endDate = dayjs
        .utc(event.event.end)
        .subtract(1, 'days')
        .format('DD.MM');

      if (startDate !== endDate) {
        const options: any = {
          month: '2-digit',
          day: '2-digit',
        };
        const starTimeString = new Date(event.event.start).toLocaleDateString(
          navigator.language,
          options
        );
        const endTimeString = dayjs(event.event.end)
          .subtract(1, 'minute')
          .toDate()
          .toLocaleDateString(navigator.language, options);
        content +=
          this.translate.instant('FROM') +
          ' ' +
          starTimeString +
          ' ' +
          this.translate.instant('TO') +
          ' ' +
          endTimeString;
      }
    } else {
      // Eintrag mit Uhrzeit
      const startTime = dayjs(event.event.start).format('HH:mm');
      const endTime = dayjs(event.event.end).format('HH:mm');
      content += startTime + ' - ' + endTime;
      content = '<div class="fc-event-main"> ' + content + ' </div>';
    }

    return {
      html: content,
    };
  }

  eventClassNames(arg) {
    if (!arg.event.allDay && arg.event.extendedProps.absenceType === 1) {
      return ['not-all-day-vacation'];
    }
    if (!arg.event.allDay && arg.event.extendedProps.absenceType === 2) {
      return ['not-all-day-Sick'];
    }
  }
  selectPrevButton(): void {
    const calendar = this.calendarComponent.getApi();
    calendar.prev();
    this.service.getAbsenceAdmin(
      calendar.getCurrentData().viewApi.currentStart,
      calendar.getCurrentData().viewApi.currentEnd
    );
  }

  selectNextButton(): void {
    const calendar = this.calendarComponent.getApi();
    calendar.next();
    this.service.getAbsenceAdmin(
      calendar.getCurrentData().viewApi.currentStart,
      calendar.getCurrentData().viewApi.currentEnd
    );
  }

  selectYearButton(): void {
    const calendar = this.calendarComponent.getApi();
    calendar.changeView('multiMonthYear');
    this.service.getAbsenceAdmin(
      calendar.getCurrentData().viewApi.currentStart,
      calendar.getCurrentData().viewApi.currentEnd
    );
  }

  absenceDayGridMonthButton(): void {
    const calendar = this.calendarComponent.getApi();
    calendar.changeView('dayGridMonth');
    this.service.getAbsenceAdmin(
      calendar.getCurrentData().viewApi.currentStart,
      calendar.getCurrentData().viewApi.currentEnd
    );
  }

  handleDayCellClassNames(arg: any): any {
    const day = arg.date.getDay(); // 0 (Sonntag) bis 6 (Samstag)
    const weekendDays =
      this.companySetting.CompanySettings.CalendarOptions.WeekendDays;

    // Wenn der Tag ein Wochenende ist, CSS-Klasse hinzufügen
    if (weekendDays.includes(day)) {
      return ['weekend-day'];
    }
  }
}
