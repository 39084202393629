<div class="row justify-content-center">
  <div class="col-xl-6 col-md-6">
    <div class="card o-hidden border-0 shadow-lg my-5">
      <div class="card-body p-0">
        <div
          class="logo-container d-flex justify-content-center align-items-center"
        >
          <img
            src="../../../../../assets/img/logo-no-name.png"
            alt="Primär-Logo"
            class="logo-primary"
          />
          <img
            src="../../../../../assets/img/Logo.PNG"
            alt="Sekundär-Logo"
            class="logo-secondary"
          />
        </div>
        <!-- Nested Row within Card Body -->
        <div class="row">
          <div class="col-lg-12">
            <div class="p-5">
              <!-- Linear Stepper -->
              <mat-horizontal-stepper #stepper linear>
                <!-- Schritt 1: Willkommen -->
                <mat-step>
                  <ng-template matStepLabel>
                    {{ "WELCOME" | translate }}
                  </ng-template>

                  <p>
                    {{
                      "THANK YOU FOR REGISTERING WITH OUR PLATFORM" | translate
                    }}
                  </p>
                  <p
                    *ngIf="!(isConfirmed$ | async)"
                    [innerHTML]="
                      'PLEASE CHECK YOUR EMAIL TO COMPLETE THE REGISTRATION PROCESS'
                        | translate
                    "
                  ></p>
                  <p
                    *ngIf="!(isConfirmed$ | async)"
                    [innerHTML]="'IF YOU HAVEN RECEIVED THE EMAIL' | translate"
                  ></p>

                  <p>
                    <b
                      [innerHTML]="
                        'WELCOME TO FIX-PLANNER! IN THE UPCOMING STEPS'
                          | translate
                      "
                    ></b>
                  </p>
                  <br />
                  <p>{{ "READY LET GET STARTED" | translate }}</p>
                  <div class="next-button">
                    <button
                      style="color: white"
                      class="btn btn-primary"
                      mat-button
                      matStepperNext
                      (click)="onFirstStepNextButtonClick()"
                    >
                      {{ "LETS GO" | translate }}
                    </button>
                  </div>
                </mat-step>

                <!-- Schritt 2: Struktur der Arbeitsprozesse -->
                <mat-step
                  [stepControl]="structureFormGroup"
                  [completed]="structureStepCompleted"
                >
                  <ng-template matStepLabel
                    >{{ "STRUCTURE_YOUR_WORK_PROCESSES" | translate }}
                  </ng-template>

                  <div class="row" style="padding-top: 10px">
                    <div class="form-group col-md-12 mb-4">
                      <h4>{{ "STRUCTURE_DECISION_TITLE" | translate }}</h4>
                      <p [innerHTML]="structureDecisionText"></p>
                    </div>
                  </div>
                  <form [formGroup]="structureFormGroup">
                    <div class="row">
                      <div class="form-group col-md-12 mb-4 d-grid">
                        <div
                          class="btn-group flex-wrap col-md-12"
                          role="group"
                          aria-label="Struktur Toggle"
                        >
                          <input
                            type="radio"
                            class="btn-check"
                            [value]="WorkStructureEnum.Departments"
                            formControlName="structureCtrl"
                            name="structureCtrl"
                            id="btnradioDepartments"
                            autocomplete="off"
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="btnradioDepartments"
                          >
                            <img
                              src="./assets/icons/department.png"
                              style="
                                width: 30px;
                                height: 30px;
                                margin-right: 5px;
                              "
                              alt="{{ 'DEPARTMENT_ICON_ALT' | translate }}"
                            />
                            {{ "DEPARTMENTS" | translate }}
                          </label>

                          <input
                            type="radio"
                            class="btn-check"
                            [value]="WorkStructureEnum.Projects"
                            formControlName="structureCtrl"
                            name="structureCtrl"
                            id="btnradioProjects"
                            autocomplete="off"
                            required
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="btnradioProjects"
                          >
                            <img
                              src="./assets/icons/project-management.png"
                              style="
                                width: 30px;
                                height: 30px;
                                margin-right: 5px;
                              "
                              alt="{{ 'PROJECT_ICON_ALT' | translate }}"
                            />
                            {{ "PROJECTS" | translate }}
                          </label>
                          <input
                            type="radio"
                            class="btn-check"
                            [value]="WorkStructureEnum.Both"
                            formControlName="structureCtrl"
                            name="structureCtrl"
                            id="btnradioHybrid"
                            autocomplete="off"
                            required
                          />
                          <label
                            class="btn btn-outline-primary"
                            for="btnradioHybrid"
                          >
                            <img
                              src="./assets/icons/hierarchy-structure.png"
                              style="
                                width: 30px;
                                height: 30px;
                                margin-right: 5px;
                              "
                              alt="{{ 'PROJECT_ICON_ALT' | translate }}"
                            />
                            {{ "HYBRID" | translate }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div class="next-button">
                    <button mat-button matStepperPrevious>
                      {{ "BACK" | translate }}
                    </button>
                    <button
                      style="color: white"
                      class="btn btn-primary"
                      mat-button
                      matStepperNext
                      [disabled]="!structureFormGroup.valid"
                      (click)="onSecondFormGroupNextButtonClick()"
                    >
                      {{ "NEXT" | translate }}
                    </button>
                  </div>
                </mat-step>

                <!-- Schritt 3: Department -->
                <mat-step
                  [stepControl]="secondFormGroup"
                  [completed]="secondStepCompleted"
                >
                  <ng-template matStepLabel>
                    <span>{{ "Department" | translate }}</span>
                  </ng-template>
                  <div
                    class="alert alert-info"
                    role="alert"
                    *ngIf="formModelDepartement.Type === 1"
                    [innerHTML]="
                      'EXPLANATION FOR THE CATEGORY DEPARTMENT' | translate
                    "
                  ></div>
                  <div
                    class="alert alert-info"
                    role="alert"
                    *ngIf="formModelDepartement.Type === 2"
                    [innerHTML]="
                      'EXPLANATION FOR THE CATEGORY PROJECT' | translate
                    "
                  ></div>
                  <app-add-departement
                    #addDepartementComponent
                    [formModel]="formModelDepartement"
                    (onSave)="onDepartementSubmit($event)"
                    [showButtons]="false"
                  ></app-add-departement>

                  <div class="next-button">
                    <button mat-button matStepperPrevious>
                      {{ "BACK" | translate }}
                    </button>
                    <button
                      mat-button
                      style="color: white"
                      class="btn btn-primary"
                      matStepperNext
                      (click)="addDepartementComponent.save()"
                      [disabled]="!addDepartementComponent.isValid"
                    >
                      {{ "Save changes" | translate }}
                    </button>
                  </div>
                </mat-step>

                <!-- Schritt 4: Qualification -->
                <mat-step
                  [stepControl]="thirdFormGroup"
                  [completed]="thirdStepCompleted"
                >
                  <ng-template matStepLabel>
                    <span>{{ "QUALIFICATION" | translate }}</span>
                  </ng-template>
                  <div
                    class="alert alert-info"
                    role="alert"
                    [innerHTML]="
                      'EDIT OR ADD QUALIFICATIONS FOR EACH DEPARTMENT'
                        | translate
                    "
                  ></div>
                  <!-- Integration von app-add-skill -->
                  <app-add-skill
                    #addSkillComponent
                    (onSave)="onSkillSubmit($event)"
                    [showButtons]="false"
                  ></app-add-skill>

                  <div class="next-button">
                    <button mat-button matStepperPrevious>
                      {{ "BACK" | translate }}
                    </button>
                    <button
                      mat-button
                      style="color: white"
                      class="btn btn-primary"
                      (click)="addSkillComponent.save()"
                      [disabled]="!addSkillComponent.isValid"
                    >
                      {{ "Save changes" | translate }}
                    </button>
                  </div>
                </mat-step>

                <!-- Schritt 5: Employer -->
                <mat-step
                  [stepControl]="fourthFormGroup"
                  [completed]="fourthStepCompleted"
                >
                  <ng-template matStepLabel>
                    <span>{{ "EMPLOYER" | translate }}</span>
                  </ng-template>
                  <div
                    class="alert alert-info"
                    role="alert"
                    [innerHTML]="
                      'ADD AS MANY EMPLOYEES AS NEEDED AND ASSIGN THEM TO DEPARTMENTS AND QUALIFICATIONS'
                        | translate
                    "
                  ></div>
                  <app-add-employee
                    #addEmployeeComponent
                    (onSave)="onEmployeeSubmit($event)"
                    [showButtons]="false"
                  ></app-add-employee>

                  <div class="next-button">
                    <button mat-button matStepperPrevious>
                      {{ "BACK" | translate }}
                    </button>
                    <button
                      mat-button
                      style="color: white"
                      class="btn btn-primary"
                      matStepperNext
                      (click)="addEmployeeComponent.save()"
                      [disabled]="!addEmployeeComponent.isValid"
                    >
                      {{ "Save changes" | translate }}
                    </button>
                  </div>
                </mat-step>

                <!-- Schritt 6: Done -->
                <mat-step [completed]="completed" [state]="state">
                  <ng-template matStepLabel>Done</ng-template>
                  <div
                    [innerHTML]="
                      'CONGRATULATIONS YOU HAVE SUCCESSFULLY ADDED DEPARTMENTS'
                        | translate
                    "
                  ></div>
                  <div class="next-button">
                    <button mat-button matStepperPrevious>
                      {{ "BACK" | translate }}
                    </button>
                    <button
                      mat-button
                      style="color: white"
                      class="btn btn-primary"
                      (click)="done()"
                    >
                      {{ "FINISH" | translate }}
                    </button>
                  </div>
                </mat-step>
              </mat-horizontal-stepper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
