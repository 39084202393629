import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthService } from '../../services/auth/auth.service';
import { DepartementService } from 'src/app/modules/departement/services/departement.service';
import { SkillService } from 'src/app/modules/skill/services/skill.service';
import { EmployeeService } from 'src/app/modules/employee/services/employee.service';
import {
  AddDepartement,
  DepartmentType,
  ProjectStatus,
} from 'src/app/modules/departement/model/add-departement';
import { AddSkill } from 'src/app/modules/skill/model/add-skill';
import { Employee } from 'src/app/modules/employee/model/employee.model';
import { Skill } from 'src/app/modules/skill/model/departement';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import {
  CompanySettings,
  WorkStructure,
} from 'src/app/modules/settings/model/company-settings.model';
import { OnboardingService } from '../../services/onboarding.service';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-registered-stepper',
  templateUrl: './registered-stepper.component.html',
  styleUrls: ['./registered-stepper.component.css'],
})
export class RegisteredStepperComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('stepper') private myStepper: MatStepper;

  structureFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  projectFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  doneFormGroup: FormGroup; // Für den Abschluss-Schritt

  skillList = [{ Name: 'Select first a Department', Id: null }];

  completed: boolean = false; // Initial nicht abgeschlossen
  state = 'error';

  departmentList: any[] = [];
  selectedValue: any;
  iconList: any[] = [];

  skillPlaceholderText = 'FIRST CHOOSE A DEPARTMENT'; // Wird im ngOnInit gesetzt

  selectedWorkStructure: WorkStructure | null = null;

  // Neue Subscription für isConfirmed
  private subscriptionIsConfirmed: Subscription;

  // Bestehende Subscriptions
  private subscriptionDepartmentList: Subscription;
  private subscriptionIconList: Subscription;
  private subscriptionSkillList: Subscription;
  private subscriptionIconTypeList: Subscription;

  // Schritt-Status
  structureStepCompleted = false;
  secondStepCompleted = false;
  projectStepCompleted = false;
  thirdStepCompleted = false;
  fourthStepCompleted = false;

  // Schritt-Submit-Status
  secondStepSubmitted = false;
  thirdStepSubmitted = false;
  projectStepSubmitted = false;
  fourthStepSubmitted = false;

  // Formmodelle
  formModelDepartement: AddDepartement = {
    Id: 0,
    Name: '',
    Type: DepartmentType.Department,
    ParentDepartmentId: null,
    ProjectStart: null,
    ProjectEnd: null,
    IsProjectCompleted: false,
    Description: '',
    Status: ProjectStatus.Planned,
    ProjectManagerId: null,
    SkillIds: [],
  };
  formModelEmployee: Employee;

  iconTypeList: any[] = [];
  iconValue = new FormControl();

  isSaveSuccessful = true;
  isConfirmed$: Observable<boolean>;
  structureDecisionText: SafeHtml;

  WorkStructureEnum = WorkStructure;
  companySettings: CompanySettings;

  // Anzahl der Schritte im Stepper
  stepperStepsCount = 6;

  constructor(
    private _formBuilder: FormBuilder,
    public departementService: DepartementService,
    public skillService: SkillService,
    private translate: TranslateService,
    public router: Router,
    public authService: AuthService,
    private sanitizer: DomSanitizer,
    public companySettingsService: CompanySettingsService,
    private onboardingService: OnboardingService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // Abonnieren der CompanySettings
    this.companySettingsService.CompanySettingsObservable.subscribe(
      (settings) => {
        if (settings) {
          this.companySettings = settings;
        }
      }
    );

    // Initialisieren der FormGroups
    this.structureFormGroup = this._formBuilder.group({
      structureCtrl: [
        this.companySettings?.WorkStructure || WorkStructure.Departments,
        Validators.required,
      ],
    });

    this.secondFormGroup = this._formBuilder.group({
      Id: [0],
      Name: ['', Validators.required],
    });

    this.projectFormGroup = this._formBuilder.group({
      projectCtrl: ['', Validators.required],
    });

    this.thirdFormGroup = this._formBuilder.group({
      Id: [0],
      Name: ['', Validators.required],
      DepartmentId: [null, Validators.required],
      IconType: [[], Validators.required],
      Icon: [null, Validators.required],
    });

    this.fourthFormGroup = this._formBuilder.group({
      Id: [0],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      DepartmentList: [[], Validators.required],
      SkillList: [[]],
      Email: ['', [Validators.required, Validators.email]],
      Role: [2, Validators.required],
      Holidays: [0, Validators.required],
      WorkingTime: [0, Validators.required],
      // Weitere Felder können hier hinzugefügt werden
    });

    // Abonnieren der Bestätigungsstatus
    this.subscriptionIsConfirmed = this.authService
      .isEmailConfirmed()
      .subscribe(
        (confirmed: boolean) => {
          this.isConfirmed$ = new Observable<boolean>((observer) => {
            observer.next(confirmed);
            observer.complete();
          });
        },
        (error) => {
          console.error('Error fetching confirmation status', error);
          this.isConfirmed$ = new Observable<boolean>((observer) => {
            observer.next(false);
            observer.complete();
          });
        }
      );

    // Abonnieren der Departement-Liste
    this.subscriptionDepartmentList =
      this.departementService.departementList.subscribe((list) => {
        this.departmentList = [{ Name: 'Nothing', Id: null }, ...list];
      });

    // Abonnieren der Icon-Liste
    this.subscriptionIconList = this.skillService.iconList.subscribe((list) => {
      this.iconList = [{ Name: 'Nothing', Id: null }, ...list];
    });

    // Abonnieren der Skill-Liste
    this.subscriptionSkillList = this.skillService.SkillList.subscribe(
      (list) => {
        this.skillList = list as Skill[];
      }
    );

    // Abonnieren der IconType-Liste
    this.subscriptionIconTypeList = this.skillService.iconTypeList.subscribe(
      (list) => {
        this.iconTypeList = list;
      }
    );

    // Holen der IconType-Liste
    this.skillService.getIconTypeList();

    // Übersetzungen laden und sanitizen
    this.translate
      .get(['STRUCTURE_DECISION_TITLE', 'STRUCTURE_DECISION_TEXT'])
      .subscribe((translations) => {
        this.structureDecisionText = this.sanitizer.bypassSecurityTrustHtml(
          translations['STRUCTURE_DECISION_TEXT']
        );
      });

    // Initialisieren der CompanySettings
    this.companySettingsService.getCompanySettings();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onboardingService.getCurrentStepper().subscribe({
        next: (currentStep) => {
          if (currentStep > 0 && currentStep <= this.stepperStepsCount) {
            this.markStepsAsCompleted(currentStep);
            // run this after 100ms to avoid ExpressionChangedAfterItHasBeenCheckedError
            setTimeout(() => {
              if (this.myStepper.steps.length <= currentStep) {
                return;
              }

              this.myStepper.selectedIndex = currentStep;
              this.cdr.detectChanges();
            }, 10);
          }
        },
        error: (error) => {
          console.error('Error fetching current stepper:', error);
        },
      });
    }, 10);
  }

  /**
   * Markiert die vorherigen Schritte als abgeschlossen basierend auf dem aktuellen Stepper-Wert.
   * @param currentStep Der aktuelle Stepper-Wert.
   */
  private markStepsAsCompleted(currentStep: number): void {
    this.structureStepCompleted = currentStep > 1; // Schritt 1 abgeschlossen, wenn currentStep > 1
    this.secondStepCompleted = currentStep > 2; // Schritt 2 abgeschlossen, wenn currentStep > 2
    this.thirdStepCompleted = currentStep > 3; // Schritt 3 abgeschlossen, wenn currentStep > 3
    this.fourthStepCompleted = currentStep > 4; // Schritt 4 abgeschlossen, wenn currentStep > 4

    console.log('Schritt-Zustände aktualisiert:', {
      structureStepCompleted: this.structureStepCompleted,
      secondStepCompleted: this.secondStepCompleted,
      thirdStepCompleted: this.thirdStepCompleted,
      fourthStepCompleted: this.fourthStepCompleted,
    });
  }

  ngOnDestroy(): void {
    // Alle Subscriptions sauber beenden
    if (this.subscriptionIsConfirmed) {
      this.subscriptionIsConfirmed.unsubscribe();
    }
    if (this.subscriptionDepartmentList) {
      this.subscriptionDepartmentList.unsubscribe();
    }
    if (this.subscriptionIconList) {
      this.subscriptionIconList.unsubscribe();
    }
    if (this.subscriptionSkillList) {
      this.subscriptionSkillList.unsubscribe();
    }
    if (this.subscriptionIconTypeList) {
      this.subscriptionIconTypeList.unsubscribe();
    }
  }

  onFirstStepNextButtonClick() {
    // Schritt 1 abgeschlossen
    this.onboardingService.updateCurrentStepper(1);
  }

  done() {
    this.completed = true;
    this.onboardingService.updateCurrentStepper(6);
    this.state = 'done';
    this.router.navigateByUrl('/planner');
  }

  onDepartementSubmit(form: AddDepartement): void {
    if (form) {
      this.secondStepSubmitted = true;
      this.secondStepCompleted = true;
      // nach 100ms zum nächsten Schritt gehen
      setTimeout(() => {
        this.myStepper.next();
        this.onboardingService.updateCurrentStepper(3); // Update auf Schritt 2
      }, 100);
    }
  }

  onSkillSubmit(form: AddSkill): void {
    if (form) {
      this.thirdStepSubmitted = true;
      this.thirdStepCompleted = true;
      setTimeout(() => {
        this.myStepper.next();
        this.onboardingService.updateCurrentStepper(4); // Update auf Schritt 3
        this.departementService.getDepartementList();
      }, 100);
    }
  }

  onEmployeeSubmit(form: Employee): void {
    if (form) {
      this.fourthStepSubmitted = true;
      this.fourthStepCompleted = true;
      setTimeout(() => {
        this.myStepper.next();
        this.onboardingService.updateCurrentStepper(5); // Update auf Schritt 4
      }, 100);
    }
  }

  onProjectSubmit(form: any): void {
    // Methode zum Speichern von Projekten
    if (form) {
      this.isSaveSuccessful = true;
      this.projectStepSubmitted = true;
      this.projectStepCompleted = true;
      // Beispiel: Update auf Schritt 5
      this.onboardingService.updateCurrentStepper(5);
    }
  }

  onIconChange(event): void {
    const id = event.value;
    this.selectedValue = this.iconList.find((x) => x.Id === id);
  }

  onIconTypeChange(event): void {
    this.skillService.getSkillIconsList(event.value);
  }

  onDepartmentChange(event) {
    this.skillList = [];
    this.skillService.getSkillList(event.value);
    if (event.value.length > 0) {
      this.skillPlaceholderText = this.translate.instant(
        'CHOOSE A QUALIFICATION'
      );
    } else {
      this.skillPlaceholderText = this.translate.instant(
        'FIRST CHOOSE A DEPARTMENT'
      );
    }
  }

  getDepartements() {
    this.departementService.getDepartementList();
  }

  onSecondFormGroupNextButtonClick() {
    const selectedValue = this.structureFormGroup.get('structureCtrl')
      .value as WorkStructure;
    this.getDepartements();
    this.structureStepCompleted = true;

    // Aktualisieren der WorkStructure in CompanySettings
    this.updateWorkStructure(selectedValue);
    this.onboardingService.updateCurrentStepper(2); // Update auf Schritt 2
  }

  onThirdFormGroupNextButtonClick() {
    this.getDepartements();
    this.thirdStepCompleted = true;
    this.thirdStepSubmitted = true;
    this.onboardingService.updateCurrentStepper(3); // Update auf Schritt 3
  }

  onFourthFormGroupNextButtonClick() {
    this.fourthStepSubmitted = true;
    this.fourthStepCompleted = true;
    this.onboardingService.updateCurrentStepper(4); // Update auf Schritt 4
  }

  updateWorkStructure(workStructure: WorkStructure) {
    this.companySettingsService.CompanySettings.WorkStructure = workStructure;
    this.companySettingsService.updateCompanySettings(
      this.companySettingsService.CompanySettings
    );
  }
}
