import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  Optional,
} from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DepartementService } from 'src/app/modules/departement/services/departement.service';
import { AddSkill } from '../../model/add-skill';
import { SkillService } from '../../services/skill.service';

@Component({
  selector: 'app-add-skill',
  templateUrl: './add-skill.component.html',
  styleUrls: ['./add-skill.component.css'],
})
export class AddSkillComponent implements OnInit, OnDestroy {
  @Input() formModel: AddSkill = {
    Id: 0,
    Name: '',
    IconId: '',
    DepartmentId: null,
    IconType: [],
    Icon: '',
  };
  @Input() showButtons: boolean = true; // Steuerung der internen Buttons
  @Output() onSave = new EventEmitter<AddSkill>(); // Ereignis zum Emittieren der gespeicherten Daten

  @ViewChild('addSkillForm') form: NgForm; // Zugriff auf das Formular

  departmentList: any[];
  iconList = [];
  iconTypeList = [];
  selectedValue: any;

  iconValue = new UntypedFormControl();

  private subscriptionDepartmentList: Subscription;
  private subscriptionIconTypeList: Subscription;

  constructor(
    public service: SkillService,
    public departmentService: DepartementService,
    public translate: TranslateService,
    @Optional() public dialogRef: MatDialogRef<AddSkillComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // Initialisieren der Department-Liste
    this.subscriptionDepartmentList =
      this.departmentService.departementList.subscribe((list) => {
        this.departmentList = [
          { Name: this.translate.instant('NO SELECTION'), Id: null },
          ...list,
        ];
      });
    this.departmentService.getDepartementList();

    // Initialisieren der Icon- und IconType-Liste
    this.subscriptionIconTypeList = this.service.iconTypeList.subscribe(
      (list) => {
        this.iconTypeList = list;
      }
    );
    // this.service.iconTypeList.next(); // Trigger fetching

    this.service.iconList.subscribe((list) => {
      this.iconList = [
        { Name: this.translate.instant('NO SELECTION'), Id: null },
        ...list,
      ];
    });
    this.service.getIconTypeList();

    // Initiale Daten setzen, falls vorhanden
    if (this.data && this.data.skill && this.data.skill.Id) {
      this.formModel = {
        Id: this.data.skill.Id,
        Name: this.data.skill.Name,
        IconId: this.data.skill.Icon.Id,
        DepartmentId: this.data.skill.DepartmentId,
        IconType: this.data.skill.IconTypeId
          ? [this.data.skill.IconTypeId]
          : null,
        Icon: this.data.skill.Icon.Id,
      };
      this.selectedValue = this.data.skill.Icon;
      if (this.formModel.IconType && this.formModel.IconType.length > 0) {
        this.service.getSkillIconsList(this.formModel.IconType);
      }
    }
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      this.service
        .submitSkill({
          Id: form.value.Id,
          Name: form.value.Name,
          IconId: form.value.Icon,
          DepartmentId: form.value.DepartmentId,
        } as AddSkill)
        .subscribe({
          next: () => {
            if (this.dialogRef) {
              // Wenn als Dialog verwendet, schließen und Daten zurückgeben
              this.dialogRef.close(this.formModel);
            } else {
              // Wenn eingebettet, das onSave Ereignis emittieren
              this.onSave.emit(this.formModel);
            }
          },
          error: (error) => {
            console.error('Fehler beim Speichern des Departements', error);
            // Optional: Fehlerbehandlung hinzufügen
          },
        });
    }
  }

  onClose(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  onIconTypeChange(event): void {
    this.service.getSkillIconsList(event.value);
  }

  onIconChange(event): void {
    const id = event.value;
    this.selectedValue = this.iconList.find((x) => x.Id === id);
  }

  ngOnDestroy(): void {
    if (this.subscriptionDepartmentList) {
      this.subscriptionDepartmentList.unsubscribe();
    }
    if (this.subscriptionIconTypeList) {
      this.subscriptionIconTypeList.unsubscribe();
    }
  }

  // Methode zum Speichern, aufrufbar von der Elternkomponente
  save(): void {
    this.onSubmit(this.form);
  }

  // Getter zur Überprüfung der Formvalidität
  get isValid(): boolean {
    return this.form?.valid;
  }
}
