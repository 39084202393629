import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { Skill } from '../../model/departement';
import { SkillService } from '../../services/skill.service';
import { AddSkillComponent } from '../add-skill/add-skill.component';

@Component({
  selector: 'app-skill-list',
  templateUrl: './skill-list.component.html',
  styleUrls: ['./skill-list.component.css'],
})
export class SkillListComponent implements OnInit {
  private subscriptionSkillList: Subscription;
  listData: MatTableDataSource<any>;
  displayedColumns: string[] = ['DepartementName', 'EmployeeCount', 'Actions'];

  alertMessage: string = this.translate.instant(
    'THE LIST IS STILL EMPTY PLEASE ADD A QUALIFICATION USING THE OPTIONS AT THE TOP RIGHT'
  );
  constructor(
    private service: SkillService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}
  skillList = [];
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  ngOnInit(): void {
    this.subscriptionSkillList = this.service.AllSkillList.subscribe((list) => {
      this.skillList = list as Skill[];
    });
    this.service.getAllSkillList();
  }

  onDepartementeDelete(id: number): void {
    this.service.deleteSkill(id);
  }

  ngOnDestroy(): void {
    this.subscriptionSkillList.unsubscribe();
  }

  @Input()
  public set departmentId(val: number) {
    this.service.getSkillList(val);
  }

  onDrop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.skillList, event.previousIndex, event.currentIndex);
    this.skillList.forEach((o: Skill, i) => (o.Weighting = i + 1));
    this.service.updateSkillWeightingList(this.skillList);
  }

  onSkillUpdate(Id: number): void {
    const skill = this.service.getSkillById(Id);
    this.updateSkill(skill);
  }

  updateSkill(skill): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { skill };
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '90vw';
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(AddSkillComponent, dialogConfig);
  }

  onSkillDelete(Id: number, Name: string): void {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      panelClass: 'custom-dialog-container',
    });
    const textDialog =
      this.translate.instant('Are you sure you want to delete this Skill') +
      '?<br/><b>' +
      Name +
      '</b> ';
    this.dialogRef.componentInstance.confirmMessage = textDialog;

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.service.deleteSkill(Id);
      }
      this.dialogRef = null;
    });
  }
}
