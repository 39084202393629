import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { EChartsOption } from 'echarts';
import { ReplaySubject, Subject, Subscription, tap } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/core/component/confirmation-dialog/confirmation-dialog.component';
import { AddEmployeeInWorkShiftComponent } from 'src/app/modules/admin/component/add-employee-in-work-shift/add-employee-in-work-shift.component';
import { EmployeeListByDateDto } from 'src/app/modules/admin/model/department-dto.model';
import { ResourceXY } from 'src/app/modules/admin/model/resource-x-y.model';
import { TimeEvent } from 'src/app/modules/admin/model/time-event.model';
import { CalendarService } from 'src/app/modules/admin/services/calendar.service';
import { AvailabilityService } from 'src/app/modules/availability/services/availability.service';
import { EmployeeService } from 'src/app/modules/employee/services/employee.service';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import { v4 as uuidv4 } from 'uuid';
import { TimeRecordingService } from '../../services/time-recording.service';
import { ShowTimeRecordingDetailsComponent } from '../show-time-recording-details/show-time-recording-details.component';
import { AbsenceType, EventType } from 'src/app/core/services/const';
@Component({
  selector: 'app-admin-time-recording-timeline',
  templateUrl: './admin-time-recording-timeline.component.html',
  styleUrls: ['./admin-time-recording-timeline.component.css'],
})
export class AdminTimeRecordingTimelineComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  /*.initOptions..*/
  currentCalendarData: any;
  isAdding = false;
  isCopy = false;
  queriedEmployee = 0;
  queriedPositionId = 0;
  calenderPosition: any = null;
  currentEventId: null;
  resourceListXY: ResourceXY[] = [];
  IncomeTypeId = '';
  translatedTextOnlyDayView = this.translate.instant(
    'THIS OPTION IS ONLY AVAILABLE IN DAY VIEW'
  );

  eventsAndScheduleRequirementList: { [key: number]: [] } = {};
  chartOption2: EChartsOption;

  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  calendarApi: any;
  HTMLElement: HTMLImageElement;
  EmployeeSearchId: number;
  EmployeeSearchText: string;
  eventNumber = 1;

  alertMessage: string = this.translate.instant(
    'BEFORE PROCEEDING WITH THIS VIEW WE WOULD LIKE TO REMIND YOU THAT YOU '
  );
  public filteredCategory: ReplaySubject<EmployeeListByDateDto[]> =
    new ReplaySubject<EmployeeListByDateDto[]>(1);
  public categoryFilterCtrl: UntypedFormControl = new UntypedFormControl();
  employeeCategory: EmployeeListByDateDto[];
  protected _onDestroy = new Subject<void>();
  @ViewChild('EmployeeSearch', { static: true }) categorySelect: MatSelect;

  start =
    dayjs().day() > 5
      ? dayjs().add(1, 'week').format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD');

  calendarOptions: CalendarOptions = {
    plugins: [resourceTimelinePlugin, interactionPlugin],
    schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
    locale: this.translate.currentLang,
    firstDay: this.companyService.CompanySettings.CalendarOptions.FirstDay,
    initialView: 'customWeek',
    slotEventOverlap: false,
    slotMinTime:
      this.companyService.CompanySettings.CalendarOptions
        .BusinessHoursStartTime,
    slotMaxTime:
      this.companyService.CompanySettings.CalendarOptions.BusinessHoursEndTime,
    slotDuration: '24:00:00',
    weekNumbers: true,
    resourceAreaWidth: this.getResourceAreaWidth(),
    height: 'auto',
    resourcesInitiallyExpanded: true,
    resourceAreaColumns: [
      {
        field: 'title',
      },
    ],
    resourceGroupField: 'departement',
    customButtons: {
      prev: {
        click: () => this.selectPrevButton(),
      },
      today: {
        text: this.translate.instant('TODAY'),
        click: () => this.selectTodayButton(),
      },
      next: {
        click: () => this.selectNextButton(),
      },
      customWeekButton: {
        text: this.translate.instant('Week'),
        click: () => this.selectCustomWeekButton(),
      },
      customDayButton: {
        text: this.translate.instant('Day'),
        click: () => this.selectCustomDayButton(),
      },
    },
    headerToolbar: {
      left: 'prev,today,next,customDayButton,customWeekButton',
      center: 'title',
      right: '',
    },
    views: {
      customDay: {
        type: 'resourceTimeline',
        duration: { day: 1 },
        slotDuration: { hour: 0.5 },
        buttonText: this.translate.instant('Day'),
        titleFormat: {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          weekday: 'long',
        },
        slotLabelFormat: [
          { weekday: 'long' }, // top level of text
          { hour: '2-digit' }, // lower level of text
        ],
      },
      customWeek: {
        type: 'resourceTimeline',
        duration: { weeks: 1 },
        slotDuration: { days: 1 },
        buttonText: this.translate.instant('Week'),
        slotLabelFormat: [
          { week: 'short' },
          {
            weekday: 'long',
            month: 'numeric',
            day: 'numeric',
            omitCommas: true,
          },
        ],
        weekText: this.translate.instant('calendar week'),
      },
      customMonth: {
        type: 'resourceTimeline',
        duration: { month: 1 },
        slotDuration: { days: 1 },
        buttonText: this.translate.instant('Month'),
      },
    },
    businessHours: {
      startTime:
        this.companyService.CompanySettings.CalendarOptions
          .BusinessHoursStartTime,
      endTime:
        this.companyService.CompanySettings.CalendarOptions
          .BusinessHoursEndTime,

      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      // days of week. an array of zero-based day of week integers (0=Sunday)
      // (Monday-Thursday in this example)
    },
    weekText: this.translate.instant('calendar week'),
    resourceOrder: 'sortOrder',
    aspectRatio: 1.6,
    displayEventTime: true,
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    handleWindowResize: true,
    eventClick: this.showTimeRecordingDetails.bind(this),
    eventResizeStart: this.handleEventResize.bind(this),
    eventContent: this.handleEventContent.bind(this),
    select: this.handleDateSelectTimeRecording.bind(this),
    resourceLabelContent: this.handleResourceLabelContent.bind(this),
    slotLabelContent: this.handleSlotLabelContent.bind(this),
    slotLaneClassNames: this.handleDayCellClassNames.bind(this),
  };

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('calendar') calendarComponentPostition: ElementRef;

  @ViewChild('EmployeeSearchInput') EmployeeSearch: ElementRef;

  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  publishButtonColor: string;
  searchKeywords: string;
  recordingWorkColor: string;
  constructor(
    private service: CalendarService,
    private timeRecordingService: TimeRecordingService,
    private cdr: ChangeDetectorRef,
    public employeeService: EmployeeService,
    private dialog: MatDialog,
    private availabilityService: AvailabilityService,
    private el: ElementRef,
    private translate: TranslateService,
    private zone: NgZone,
    private renderer: Renderer2,
    private companyService: CompanySettingsService,
    private companySetting: CompanySettingsService
  ) {
    Window['AdminTimeRecordingTimelineComponent'] = this;
  }
  private subscriptionEmployeeEventList: Subscription;

  getResourceAreaWidth(): string {
    const width = window.innerWidth;

    if (width < 576) {
      // Mobiltelefone
      return '130px';
    } else if (width >= 576 && width < 768) {
      // Kleine Tablets
      return '150px';
    } else if (width >= 768 && width < 992) {
      // Große Tablets
      return '200px';
    } else {
      // Desktop
      return '250px';
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.calendarOptions.resourceAreaWidth = this.getResourceAreaWidth();
    // Falls erforderlich, können Sie den Kalender neu rendern
    // this.calendarComponent.getApi().updateSize();
  }

  ngOnInit(): void {
    this.recordingWorkColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--event-vertical-line-time-recording-work')
      .trim();
    this.subscriptionEmployeeEventList =
      this.timeRecordingService.employeeTimelineEventList
        .pipe(
          tap((timelineEvents) => {
            if (timelineEvents == null) {
              return;
            }

            if (this.calendarComponent) {
              const calendar = this.calendarComponent.getApi();
              if (
                calendar &&
                JSON.stringify(this.calendarOptions.events) !==
                  JSON.stringify(timelineEvents.calenderEvents)
              ) {
                calendar.removeAllEvents();
              }
              this.eventNumber = timelineEvents.calenderResources.length;

              this.calendarOptions.events = [
                [],
                ...timelineEvents.calenderEvents,
              ];
              this.calendarOptions.resources = [
                [],
                ...timelineEvents.calenderResources,
              ];
              calendar.refetchEvents();
              this.cdr.detectChanges();
            }
          })
        )
        .subscribe();
  }
  isMobileView() {
    return window.innerWidth <= 1168;
  }
  ngAfterViewInit(): void {
    this.calendarApi = this.calendarComponent.getApi();

    this.currentCalendarData = this.calendarApi.getCurrentData();

    this.timeRecordingService.getTimeRecordingTimelineForCompany(
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      '*'
    );

    this.HTMLElement = this.el.nativeElement.querySelector(
      '.fc-datagrid-cell-frame'
    );

    this.renderer.appendChild(
      // Employee Search
      this.HTMLElement,
      this.EmployeeSearch.nativeElement
    );
  }

  onSearchKeywordsChange(keyWord): void {
    this.searchKeywords = keyWord;
    this.timeRecordingService.getTimeRecordingTimelineForCompany(
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      keyWord
    );
  }

  ngOnDestroy(): void {
    this.subscriptionEmployeeEventList.unsubscribe();
  }

  selectPrevButton(): void {
    this.calendarApi.prev();
    this.currentCalendarData = this.calendarApi.getCurrentData();
    this.timeRecordingService.getTimeRecordingTimelineForCompany(
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      '*'
    );
  }
  selectTodayButton(): void {
    this.calendarApi.today();
    this.currentCalendarData = this.calendarApi.getCurrentData();
    this.timeRecordingService.getTimeRecordingTimelineForCompany(
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      '*'
    );
  }
  selectNextButton(): void {
    this.currentCalendarData = this.calendarApi.getCurrentData();
    this.calendarApi.next();
    this.timeRecordingService.getTimeRecordingTimelineForCompany(
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      '*'
    );
  }

  selectCustomWeekButton(): void {
    const calendar = this.calendarComponent.getApi();
    calendar.changeView('customWeek');
    this.currentCalendarData = calendar.getCurrentData();
    this.timeRecordingService.getTimeRecordingTimelineForCompany(
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      '*'
    );
  }
  selectCustomDayButton(): void {
    const calendar = this.calendarComponent.getApi();
    calendar.changeView('customDay');
    this.currentCalendarData = calendar.getCurrentData();
    this.timeRecordingService.getTimeRecordingTimelineForCompany(
      this.currentCalendarData.viewApi.currentStart,
      this.currentCalendarData.currentViewType === 'customDay',
      '*'
    );
  }
  handleDateSelectTimeRecording(selectInfo: DateSelectArg): void {
    this.currentCalendarData = this.calendarApi.getCurrentData();

    this.addEditTimeRecordingEvent({
      employeeId: selectInfo.resource._resource.extendedProps.employeeId,
      departmentId: selectInfo.resource._resource.extendedProps.departementId,
      skillId: selectInfo.resource._resource.extendedProps.skillId,
      start: selectInfo.startStr,
      title: selectInfo.resource._resource.title,
      addModus: true,
    });
  }

  handleEventResize(event: any): void {
    if (parseInt(event.event.id) !== this.queriedEmployee) {
      this.currentEventId = event.event.extendedProps.eventId;
      this.getEmployeeAvailability(event.event.id);
    }
  }
  handleEventContent(event): any {
    if (
      event.isDraggable &&
      event.isDragging &&
      parseInt(event.event.id) !== this.queriedEmployee
    ) {
      this.currentEventId = event.event.extendedProps.eventId;
      this.getEmployeeAvailability(event.event.id);
    }

    if (event.event.extendedProps.absenceType === 3) {
      return {
        html:
          event.event._def.title +
          ' ' +
          this.translate.instant('IS NOT AVAILABLE IN THE AREA MARKED IN RED'),
      };
    }

    let breakTime = this.convertMinutesToTime(event.event.extendedProps.break);
    const workingTime = this.convertMinutesToTime(
      event.event.extendedProps.totalWorkTimeDay
    );

    const iconColor = '#f08d41ff';
    const icon = event.event.extendedProps.compliance
      ? '<i style="position: absolute; bottom: 0; right: 0; color: ' +
        iconColor +
        ' " class="fa-light fa-triangle-exclamation"></i>'
      : '';

    // Art des Eintrags
    let absenceType = '';
    if (event.event.extendedProps.absenceType === AbsenceType.Sick) {
      absenceType = this.translate.instant('Sick');
    }

    if (event.event.extendedProps.absenceType === AbsenceType.Vacation) {
      absenceType = this.translate.instant('Vacation');
    }

    let brakeTimeHtml = '';
    if (event.event.extendedProps.eventType === EventType.TimeRecording) {
      brakeTimeHtml =
        '<i  class="c fa-light fa-mug-hot"> ' + breakTime + '</i>';
    }

    return {
      html:
        '<div class="fc-event-time">' +
        event.timeText +
        '  ' +
        absenceType +
        // show the icon in the right side of the event
        '<div class="a hidden-mobile">' +
        '<i  class="b fa-solid fa-clock"> ' +
        workingTime +
        '</i>' +
        brakeTimeHtml +
        '</div>' +
        '</div>' +
        event.event._def.title +
        ' <em class="' +
        event.event._def.extendedProps.icon +
        ' fa-lg' +
        '"></em>' +
        icon,
    };
  }

  convertMinutesToTime(minutes: number): string {
    if (isNaN(minutes) || minutes < 0) {
      return '00:00';
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    const hoursStr = hours < 10 ? '0' + hours : hours;
    const minutesStr =
      remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes;

    return `${hoursStr}:${minutesStr}`;
  }

  getEmployeeAvailability(id: string): void {
    this.queriedEmployee = parseInt(id);
    this.availabilityService.getAvailabilityEvent(false, this.queriedEmployee);
  }

  showTimeRecordingDetails(clickedEvent): void {
    // get employee id and startDate from the event by the given eventId
    const event = (this.calendarOptions.events as any[]).find(
      (event) => event.eventId === clickedEvent.event.extendedProps.eventId
    );

    let eventDialog = {
      ...event,
    };
    eventDialog.addModus = false;

    this.addEditTimeRecordingEvent(eventDialog);
  }

  addEditTimeRecordingEvent(event: any): void {
    const employeeId = event.employeeId;
    const startDate = event.start;
    const employeeName = event.title;
    const departmentId = event.departmentId;
    const skillId = event.skillId;
    event.calendarInfo = {
      currentWeekStart: this.currentCalendarData.viewApi.currentStart,
      oneDay: this.currentCalendarData.currentViewType === 'customDay',
      searchKeywords: '*',
    };

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.restoreFocus = false;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '90vw';
    dialogConfig.data = {
      employeeId,
      startDate,
      employeeName,
      departmentId,
      skillId,
      addModus: event.addModus,
      calendarInfo: event.calendarInfo,
    };
    dialogConfig.panelClass = 'custom-dialog-container';
    this.zone.run(() => {
      this.dialog.open(ShowTimeRecordingDetailsComponent, dialogConfig);
    });
  }

  handleDayClick(clickedDay): any {
    clickedDay = dayjs(clickedDay).toDate();
    const calendar = this.calendarComponent.getApi();
    calendar.gotoDate(clickedDay);
    calendar.changeView('customDay');
    if (calendar) {
      this.currentCalendarData = calendar.getCurrentData();
      this.timeRecordingService.getTimeRecordingTimelineForCompany(
        this.currentCalendarData.viewApi.currentStart,
        this.currentCalendarData.currentViewType === 'customDay',
        '*'
      );
    }
  }

  handleSlotLabelContent(event): any {
    if (
      (event.level === 1 &&
        this.currentCalendarData?.currentViewType !== 'customDay') ||
      (event.level === 0 &&
        this.currentCalendarData?.currentViewType === 'customDay')
    ) {
      // if event.date is in the past, do not show the warning
      if (dayjs(event.date).isBefore(dayjs(), 'day')) {
        return {
          html:
            '<span class="time-line-header-day" onclick="Window.AdminTimeRecordingTimelineComponent.handleDayClick(\'' +
            dayjs(event.date).format('YYYY-MM-DD') +
            '\')">' +
            event.text +
            '</span>',
        };
      }

      return {
        html:
          '<span class="time-line-header-day" onclick="Window.AdminTimeRecordingTimelineComponent.handleDayClick(\'' +
          dayjs(event.date).format('YYYY-MM-DD') +
          '\')">' +
          event.text +
          '</span>',
      };
    }
    return true;
  }

  handleResourceLabelContent(event): any {
    const extendedProps = event.resource._resource.extendedProps;

    if (extendedProps.sortOrder === -99) {
      return {
        html: `
          <div class="row">
            <div style="padding-left: 0px;padding-right: 0px;" class="col-md-3 mb-2">
              <div style="position: relative;display: flex;justify-content: center;align-items: center;">
                <div id="progress-spinner"></div>
                <div style="padding-left: 0px;padding-right: 0px; font-size: medium; margin-bottom: 0px !important;" class="col-md-9 mb-4">
                  ${this.translate.instant('Offene Schichten')}
                </div>
              </div>
            </div>
          </div>
        `,
      };
    }

    const { employeeId, timeAvailablePercent, timeAvailable, skillIcons } =
      extendedProps;
    const colorPriority = this.recordingWorkColor;
    let color = '#1b5e1f8f';

    if (employeeId > 0) {
      if (timeAvailablePercent >= 80) {
        color = '#F9A8258f';
      }
      if (timeAvailablePercent > 99) {
        color = '#B71C1C8f';
      }
      return {
        html: `
          <div class="row">
            <div style="padding-left: 0px;padding-right: 0px;" class="col-md-3 mb-2">
              <div class="hidden-small-mobile" style="position: relative;display: flex;justify-content: center;align-items: center;">
                <div id="middle-circle">${timeAvailablePercent}%</div>
                <div id="progress-spinner" style="background: conic-gradient(${color} ${timeAvailablePercent}%, rgb(242, 242, 242) ${timeAvailablePercent}%);"></div>
              </div>
            </div>
            <div style="padding-left: 0px;padding-right: 0px; font-size: medium; margin-bottom: 0px !important; padding-left: 5px;" class="col-md-9 mb-4">
              ${event.resource._resource.title}<br/>
              <div class="badge badge-pill badge-primary" style="font-size: small; background-color: ${colorPriority}">
                ${timeAvailable}
              </div>
              ${skillIcons}
            </div>
          </div>
        `,
      };
    }
  }

  handleDayCellClassNames(arg: any): any {
    const day = arg.date.getDay(); // 0 (Sonntag) bis 6 (Samstag)
    const weekendDays =
      this.companySetting.CompanySettings.CalendarOptions.WeekendDays;

    // Wenn der Tag ein Wochenende ist, CSS-Klasse hinzufügen
    if (weekendDays.includes(day)) {
      return ['weekend-day'];
    }
  }
}
