export class AddDepartement {
  Id: number;
  Name: string;
  Type: DepartmentType = DepartmentType.Department;
  ParentDepartmentId?: number;
  ProjectStart?: Date;
  ProjectEnd?: Date;
  IsProjectCompleted?: boolean;
  Description?: string;
  Status?: ProjectStatus = ProjectStatus.Planned;
  ProjectManagerId?: number;
  SkillIds: number[] = [];
}

export enum DepartmentType {
  Department = 1,
  Project = 2,
}

export enum ProjectStatus {
  Planned = 1,
  InProgress = 2,
  Completed = 3,
  OnHold = 4,
  Cancelled = 5,
}
